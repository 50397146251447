import { withDependencies } from '@wix/thunderbolt-ioc'
import { TpaHandlerProvider } from '@wix/thunderbolt-symbols'
import { ITpaPopup, OpenPopupOptions } from '../types'
import { TpaPopupSymbol } from '../symbols'

export type MessageData = { url: string } & OpenPopupOptions

export const OpenPersistentPopupHandler = withDependencies(
	[TpaPopupSymbol],
	({ openPopup }: ITpaPopup): TpaHandlerProvider => ({
		getTpaHandlers() {
			return {
				openPersistentPopup(compId, { url, ...options }: MessageData): Promise<any> {
					return openPopup(url, { ...options, persistent: true }, compId)
				},
			}
		},
	})
)
