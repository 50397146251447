import { named, withDependencies, optional } from '@wix/thunderbolt-ioc'
import {
	BrowserWindow,
	BrowserWindowSymbol,
	BusinessLogger,
	BusinessLoggerSymbol,
	CurrentRouteInfoSymbol,
	IMultilingual,
	IPropsStore,
	PageFeatureConfigSymbol,
	pageIdSym,
	Props,
	SiteFeatureConfigSymbol,
} from '@wix/thunderbolt-symbols'
import { ICurrentRouteInfo } from 'feature-router'
import { TpaCompData, TpaPageConfig, ITpaSrcBuilder, ExtraQueryParams } from './types'
import { name } from './symbols'
import { name as tpaCommonsName, TpaCommonsSiteConfig } from 'feature-tpa-commons'
import { ISessionManager, SessionManagerSymbol } from 'feature-session-manager'
import { ConsentPolicySymbol, IConsentPolicy } from 'feature-consent-policy'
import { PolicyDetails } from '@wix/cookie-consent-policy-client'
import { CommonConfigSymbol, ICommonConfig } from 'feature-common-config'
import _ from 'lodash'
import { MultilingualSymbol } from 'feature-multilingual'

const appendInnerRoute = (parts: Array<string>, targetUrl: string) => {
	if (parts.length === 0) {
		return targetUrl
	}
	const innerRoute = parts.join('/')
	const addr = new URL(targetUrl)
	addr.pathname += `/${innerRoute}`
	return addr.href
}

const addConsentPolicyIfExists = (consentPolicyApi: IConsentPolicy) => {
	const consentPolicy = consentPolicyApi.getCurrentConsentPolicy()

	function isDefaultConsentPolicy(policytoTest: PolicyDetails) {
		return policytoTest.defaultPolicy && _.every(policytoTest.policy)
	}

	return !isDefaultConsentPolicy(consentPolicy) && !!consentPolicyApi._getConsentPolicyHeader()['consent-policy']
		? decodeURIComponent(consentPolicyApi._getConsentPolicyHeader()['consent-policy']!)
		: undefined
}

export const TpaSrcBuilder = withDependencies(
	[
		Props,
		named(SiteFeatureConfigSymbol, tpaCommonsName),
		named(PageFeatureConfigSymbol, name),
		SessionManagerSymbol,
		ConsentPolicySymbol,
		BrowserWindowSymbol,
		CommonConfigSymbol,
		CurrentRouteInfoSymbol,
		BusinessLoggerSymbol,
		pageIdSym,
		optional(MultilingualSymbol),
	],
	(
		props: IPropsStore,
		{
			widgetsClientSpecMapData,
			siteRevision,
			viewMode,
			appSectionParams,
			externalBaseUrl,
			requestUrl,
			extras,
			deviceType,
			tpaDebugParams,
			locale,
		}: TpaCommonsSiteConfig,
		{ tpaInnerRouteConfig }: TpaPageConfig,
		sessionManager: ISessionManager,
		consentPolicyApi: IConsentPolicy,
		browserWindow: BrowserWindow,
		commonConfigAPI: ICommonConfig,
		currentRouteInfo: ICurrentRouteInfo,
		businessLogger: BusinessLogger,
		pageId: string,
		multiLingual?: IMultilingual
	): ITpaSrcBuilder => {
		const resolveCurrentUrl = () => {
			return browserWindow ? browserWindow.location.href : requestUrl
		}

		const resolveAppSectionParams = () => {
			if (browserWindow) {
				const json = new URL(resolveCurrentUrl()).searchParams.get('appSectionParams')
				return JSON.parse(decodeURIComponent(json || '{}')) || {}
			}
			return appSectionParams
		}

		const withTpaInnerRoute = (tpaUrl: string, applicationId: number) => {
			const hasTpaInnerRoute = Boolean(
				tpaInnerRouteConfig.tpaPageUri &&
					!_.isNil(applicationId) &&
					applicationId === tpaInnerRouteConfig.tpaApplicationId
			)

			const extractInnerRoute = () => {
				const [rawPathname] = resolveCurrentUrl()
					.replace(externalBaseUrl, '')
					.split('?')
				const parts = rawPathname.split('/')
				const i = parts.indexOf(tpaInnerRouteConfig.tpaPageUri || '')
				if (i < 0 || parts[i] !== tpaInnerRouteConfig.tpaPageUri) {
					return null
				}
				const [, ...remains] = parts.splice(i)
				return remains
			}

			const innerRouteParts = hasTpaInnerRoute && extractInnerRoute()
			return innerRouteParts ? appendInnerRoute(innerRouteParts, tpaUrl) : tpaUrl
		}

		return {
			buildSrc(
				id: string,
				tpaCompData: Partial<TpaCompData>,
				baseUrl: string,
				extraQueryParams: ExtraQueryParams = {}
			) {
				const { widgetId, width, height, externalId, templateId } = tpaCompData
				const { applicationId, appDefinitionId } = widgetsClientSpecMapData[widgetId!] || {}

				const routerPublicData = currentRouteInfo.getCurrentRouteInfo()!.dynamicRouteData?.publicData
				// when templateCompId exists, it should be the compId passed to the tpa e.g in case the controller lives within a shared block
				// compId is still needed for js-sdk
				const compId = templateId || id
				const viewerCompId = id

				const commonConfig = JSON.stringify(commonConfigAPI.getCommonConfig())

				const urlQueryParams: Record<string, string | undefined | null> = {
					instance: `${sessionManager.getAppInstanceByAppDefId(appDefinitionId)}`,
					pageId,
					compId,
					viewerCompId,
					siteRevision: `${siteRevision}`,
					viewMode,
					deviceType,
					externalId,
					locale,
					commonConfig,
					lang: multiLingual?.currentLanguage?.languageCode,
					'consent-policy': addConsentPolicyIfExists(consentPolicyApi),
					currency: extras.currency,
					width: _.isNumber(width) ? `${width}` : null,
					height: _.isNumber(height) ? `${height}` : null,
					...resolveAppSectionParams(),
					...extraQueryParams,
				}

				if (routerPublicData) {
					urlQueryParams.routerData = JSON.stringify(routerPublicData)
				}

				_.entries(tpaDebugParams).forEach(([key, value]) => {
					if (!_.isNil(value)) {
						urlQueryParams[key] = value
					}
				})

				const targetSrc = withTpaInnerRoute(baseUrl, applicationId)
				const url = new URL(targetSrc)

				_.entries(urlQueryParams).forEach(([key, value]) => {
					if (!_.isNil(value)) {
						url.searchParams.set(key, value)
					}
				})
				return url.href
			},
		}
	}
)
