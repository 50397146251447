import { withDependencies, named, multi } from '@wix/thunderbolt-ioc'
import {
	BrowserWindowSymbol,
	PageFeatureConfigSymbol,
	TpaHandlerExtras,
	TpaHandlerProvider,
	TpaHandlerProviderSymbol,
	TpaHandlers,
} from '@wix/thunderbolt-symbols'
import { BaseResponse, TpaPageConfig } from './types'
import { name } from './symbols'
import { ITpaHandlersManager, TpaIFrame } from 'feature-tpa-commons'
import { runtimeTpaCompIdBuilder } from '@wix/thunderbolt-commons'

const sendResponseTPA = ({
	tpa,
	callId,
	status,
	res,
}: {
	tpa: TpaIFrame
	callId: string
	status: boolean
	res: any
}) => {
	const tpaResponse: BaseResponse<any> = {
		callId,
		intent: 'TPA_RESPONSE',
		status,
		res,
	}
	tpa.postMessage(JSON.stringify(tpaResponse), '*')
}

export const TpaHandlersManager = withDependencies(
	[BrowserWindowSymbol, named(PageFeatureConfigSymbol, name), multi(TpaHandlerProviderSymbol)],
	(
		window: Window,
		tpaPageConfig: TpaPageConfig,
		handlerProviders: Array<TpaHandlerProvider>
	): ITpaHandlersManager => {
		const handlers: TpaHandlers = Object.assign(
			{},
			...handlerProviders.map((provider) => provider.getTpaHandlers())
		)

		return {
			async handleMessage(tpa, { type, callId, compId, data }) {
				const handler = handlers[type]
				if (!handler) {
					console.warn(`TpaHandlerError: ${type} handler is not implemented`)
					return
				}
				const originCompId = runtimeTpaCompIdBuilder.getOriginCompId(compId)
				const tpaCompData = tpaPageConfig.widgets[originCompId] || {
					applicationId: '',
					widgetId: '',
				}

				const extras: TpaHandlerExtras = {
					callId,
					tpa,
					appDefinitionId: tpaCompData.appDefinitionId,
					widgetId: tpaCompData.widgetId,
					originCompId,
				}

				const result = handler(compId, data, extras)
				if (typeof result === 'undefined') {
					// TODO rethink this. it's a very weird way of saying:
					// if the handler returns a promise or some defined value,
					// someone in the iframe is waiting for a response. otherwise return.
					return
				}

				try {
					const res = await result
					sendResponseTPA({ tpa, callId, status: true, res })
				} catch (e) {
					sendResponseTPA({
						tpa,
						callId,
						status: false,
						res: { message: e.message, name: e.name, stack: e.stack },
					})
				}
			},
		}
	}
)
