import { GetAdsOnPageHandler } from './getAdsOnPageHandler'
import { ReplaceSectionStateHandler } from './replaceSectionStateHandler'
import { ApplicationLoadingStepHandler } from './applicationLoadingStepHandler'
import { ApplicationLoadedHandler } from './applicationLoadedHandler'
import { OpenPersistentPopupHandler } from './openPersistentPopupHandler'
import { RefreshCurrentMemberHandler } from './refreshCurrentMemberHandler'
import { OpenPopupHandler } from './openPopupHandler'
import { GetAppVendorProductIdHandler } from './getAppVendorProductIdHandler'
import { ResizeWindowHandler } from './resizeWindowHandler'
import { OnReadyHandler } from './onReadyHandler'
import { NavigateToSectionHandler } from './navigateToSectionHandler'
import { BoundingRectAndOffsetsHandler } from './boundingRectAndOffsetsHandler'
import { SetPageMetadataHandler } from './setPageMetadataHandler'
import { OpenModalHandler } from './openModalHandler'
import { NavigateToHandler } from './navigateToHandler'
import { GetApplicationFieldsHandler } from './getApplicationFieldsHandler'
import { RemoveEventListenerHandler } from './removeEventListenerHandler'
import { PublishHandler } from './publishHandler'
import { RevalidateSessionHandler } from './revalidateSessionHandler'
import { FactoryWithDependencies } from '@wix/thunderbolt-ioc'
import { AppIsAliveHandler } from './appIsAliveHandler'
import { RegisterEventListenerHandler } from './registerEventListenerHandler'
import { CloseWindowHandler } from './closeWindowHandler'
import { GetCurrentPageIdHandler } from './getCurrentPageIdHandler'
import { ScrollToHandler } from './scrollToHandler'
import { ScrollByHandler } from './scrollByHandler'
import { GetExternalIdHandler } from './getExternalId'
import { GetValueHandler } from './getValueHandler'
import { GetPublicDataHandler } from './getPublicDataHandler'
import { GetStyleIdHandler } from './getStyleId'
import { GetViewModeHandler } from './getViewMode'
import { GetComponentInfoHandler } from './getComponentInfo'
import { IsAppSectionInstalledHandler } from './isAppSectionInstalled'
import { GetSiteMapHandler } from './getSiteMap'
import { SiteInfoHandler } from './siteInfo'
import { GetSectionUrlHandler } from './getSectionUrl'
import { SetFullScreenMobileHandler } from './setFullScreenMobileHandler'
import { EmptyHandlers } from './emptyHandler'
import { ReportVisitorActivityHandler } from './reportVisitorActivity'

export const handlers: Array<FactoryWithDependencies> = [
	EmptyHandlers,
	AppIsAliveHandler,
	RegisterEventListenerHandler,
	CloseWindowHandler,
	GetCurrentPageIdHandler,
	ScrollToHandler,
	ScrollByHandler,
	GetExternalIdHandler,
	GetValueHandler,
	GetPublicDataHandler,
	SetPageMetadataHandler,
	RevalidateSessionHandler,
	GetViewModeHandler,
	GetStyleIdHandler,
	GetComponentInfoHandler,
	IsAppSectionInstalledHandler,
	SiteInfoHandler,
	PublishHandler,
	RemoveEventListenerHandler,
	GetSiteMapHandler,
	GetApplicationFieldsHandler,
	OpenModalHandler,
	NavigateToHandler,
	RefreshCurrentMemberHandler,
	GetSectionUrlHandler,
	BoundingRectAndOffsetsHandler,
	GetAppVendorProductIdHandler,
	ResizeWindowHandler,
	OnReadyHandler,
	NavigateToSectionHandler,
	OpenPopupHandler,
	SetFullScreenMobileHandler,
	OpenPersistentPopupHandler,
	ReportVisitorActivityHandler,
	ReplaceSectionStateHandler,
	ApplicationLoadedHandler,
	ApplicationLoadingStepHandler,
	GetAdsOnPageHandler,
]
