import { optional, withDependencies, named } from '@wix/thunderbolt-ioc'
import { INavigation, NavigationSymbol } from 'feature-navigation'
import { createLinkUtils } from '@wix/thunderbolt-commons'
import { SiteFeatureConfigSymbol, TpaHandlerProvider } from '@wix/thunderbolt-symbols'
import { IRoutingLinkUtilsAPI, RoutingLinkUtilsAPISymbol } from 'feature-router'
import { IPopupsLinkUtilsAPI, PopupsLinkUtilsAPISymbol } from 'feature-popups'
import { LinkData, PageLinkData } from '@wix/thunderbolt-becky-types'
import { PageTransitionsSymbol, IPageTransition } from 'feature-page-transitions'
import { name as tpaCommonsName, TpaCommonsSiteConfig } from 'feature-tpa-commons'

export type NavigateToMessageData = {
	link: LinkData
}

export type NavigateToPageMessageData = {
	pageId: string
	anchorId?: string
	noTransition?: boolean
}

export const NavigateToHandler = withDependencies(
	[
		named(SiteFeatureConfigSymbol, tpaCommonsName),
		NavigationSymbol,
		RoutingLinkUtilsAPISymbol,
		optional(PopupsLinkUtilsAPISymbol),
		optional(PageTransitionsSymbol),
	],
	(
		{ metaSiteId, userFileDomainUrl, routersConfig }: TpaCommonsSiteConfig,
		navigation: INavigation,
		routingLinkUtilsAPI: IRoutingLinkUtilsAPI,
		popupsLinkUtilsAPI: IPopupsLinkUtilsAPI,
		pageTransitions?: IPageTransition
	): TpaHandlerProvider => {
		const linkUtils = createLinkUtils({
			routingInfo: routingLinkUtilsAPI.getLinkUtilsRoutingInfo(),
			metaSiteId,
			userFileDomainUrl,
			routersConfig,
			popupPages: popupsLinkUtilsAPI?.getPopupPages(),
		})

		const getLinkProps = (link: LinkData) => {
			const linkUrl = linkUtils.getLinkUrlFromDataItem(link)
			return linkUtils.getLinkProps(linkUrl)
		}

		return {
			getTpaHandlers() {
				return {
					navigateToPage(_compId: string, { pageId, anchorId, noTransition }: NavigateToPageMessageData) {
						const pageLinkData: PageLinkData = { type: 'PageLink', pageId, target: '_self' }
						const linkProps = getLinkProps(pageLinkData)

						if (anchorId) {
							if (routingLinkUtilsAPI.getLinkUtilsRoutingInfo().pageId === pageId) {
								linkProps.anchorCompId = anchorId
							} else {
								linkProps.anchorDataId = anchorId
							}
						}

						if (noTransition && pageTransitions) {
							pageTransitions.disableNextTransition()
						}

						navigation.navigateTo(linkProps)
					},
					navigateTo(_compId: string, { link }: NavigateToMessageData) {
						const linkProps = getLinkProps(link)

						navigation.navigateTo(linkProps)
					},
				}
			},
		}
	}
)
