import _ from 'lodash'
import { withDependencies } from '@wix/thunderbolt-ioc'
import { IPropsStore, Props } from '@wix/thunderbolt-symbols'
import { MasterPageTpaPropsCache } from './types'

export const TpaPropsCacheFactory = withDependencies(
	[Props],
	(props: IPropsStore): MasterPageTpaPropsCache => {
		const cache: { [compId: string]: any } = {}

		return {
			cacheProps(compId: string) {
				const propsToCache = _.omitBy(props.get(compId), _.isFunction)
				cache[compId] = _.isEmpty(propsToCache) ? null : propsToCache
			},
			getCachedProps(compId: string) {
				return cache[compId]
			},
		}
	}
)
