import { withDependencies } from '@wix/thunderbolt-ioc'
import { TpaHandlerProvider } from '@wix/thunderbolt-symbols'
import { TpaPopupSymbol } from '../symbols'
import { ITpaPopup, OpenPopupOptions } from '../types'

export type MessageData = { url: string } & OpenPopupOptions

export const OpenPopupHandler = withDependencies(
	[TpaPopupSymbol],
	({ openPopup }: ITpaPopup): TpaHandlerProvider => ({
		getTpaHandlers() {
			return {
				openPopup(compId, { url, ...options }: MessageData): Promise<any> {
					return openPopup(url, options, compId)
				},
			}
		},
	})
)
